import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Roles, RoleService } from '@app/core/services/role.service';
import { combineLatest, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard  {
  constructor(private roleService: RoleService,private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const roles: Roles[] = route.data['role'] as Roles[];
    
    
    if (!Array.isArray(roles)) {
      return false; 
    }
  
    const observables = roles.map((x) => {
      return this.roleService.hasRole(<Roles>x);
    });
  
    return combineLatest(observables).pipe(
      map((result) => {
        let res = result.some((element) => element);
        if (!res) {
          this.router.navigate(['not-found']);
        }
        return res;
      })
    );
  }
}
